import * as z from 'zod'
import { NumericSchema } from '../schemas'
import { Role } from './roles'
import { Roles } from './roles-mask'

const ClerkId = z
  .string()
  .refine((val) => val.startsWith('user_'), 'Does not start with `user_`')
type ClerkId = z.infer<typeof ClerkId>

const StripeCustomerId = z
  .string()
  .refine((val) => val.startsWith('cus_'), 'Does not start with `cus_`')
type StripeCustomerId = z.infer<typeof StripeCustomerId>

const SessionVariable = z.enum([
  'x-hasura-role',
  'x-hasura-allowed-roles',
  'x-hasura-default-role',
  'x-hasura-user-id',
  'x-hasura-org-id',
  'x-hasura-stripe-customer-id',
  'x-hasura-clerk-id',
])

export const HasuraUser = z.object({
  role: Role,
  allowedRoles: z.union([Role, Roles]).optional(),
  defaultRole: Role.optional(),
  userId: NumericSchema,
  clerkId: ClerkId,
  stripeCustomerId: StripeCustomerId,
  orgId: NumericSchema.optional(),
})

export const HasuraSessionVariables = z
  .object({
    [SessionVariable.enum['x-hasura-allowed-roles']]:
      HasuraUser.shape.allowedRoles.optional(),
    [SessionVariable.enum['x-hasura-default-role']]:
      HasuraUser.shape.defaultRole.optional(),
    [SessionVariable.enum['x-hasura-role']]: HasuraUser.shape.role.optional(),
    [SessionVariable.enum['x-hasura-user-id']]: HasuraUser.shape.userId,
    [SessionVariable.enum['x-hasura-clerk-id']]: HasuraUser.shape.clerkId,
    [SessionVariable.enum['x-hasura-stripe-customer-id']]:
      HasuraUser.shape.stripeCustomerId,
    [SessionVariable.enum['x-hasura-org-id']]: HasuraUser.shape.orgId,
  })
  .superRefine((val, ctx) => {
    const role = val[SessionVariable.enum['x-hasura-role']]
    const allowedRoles = val[SessionVariable.enum['x-hasura-allowed-roles']]
    const defaultRole = val[SessionVariable.enum['x-hasura-default-role']]

    const roleAlt = !!allowedRoles && allowedRoles.length > 0 && !!defaultRole

    if (!role && !roleAlt) {
      ctx.addIssue({
        code: 'custom',
        path: [SessionVariable.enum['x-hasura-role']],
        message:
          'Must have either a `role`, or `allowed-roles` combined with a `default-role`',
      })
      return z.NEVER
    }
    return z.OK(val)
  })

export const getHasuraUserFromSessionVariables = z
  .function()
  .args(HasuraSessionVariables)
  .returns(HasuraUser)
  .implement((session_variables) => {
    return {
      role:
        session_variables[SessionVariable.enum['x-hasura-role']] ||
        session_variables[SessionVariable.enum['x-hasura-default-role']]! ||
        'public',
      allowedRoles:
        session_variables[SessionVariable.enum['x-hasura-allowed-roles']],
      defaultRole:
        session_variables[SessionVariable.enum['x-hasura-default-role']],
      userId: session_variables[SessionVariable.enum['x-hasura-user-id']],
      clerkId: session_variables[SessionVariable.enum['x-hasura-clerk-id']],
      stripeCustomerId:
        session_variables[SessionVariable.enum['x-hasura-stripe-customer-id']],
      orgId: session_variables[SessionVariable.enum['x-hasura-org-id']],
    }
  })
