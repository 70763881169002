import type { ScopeContext } from '@sentry/types'
// eslint-disable-next-line @purposity/purposity/require-namespace-imports
import type { ZodType, infer as Infer, TypeOf } from 'zod'

export const buildObjectModel = <
  Model extends ZodType<any>,
  ModelType = Infer<Model>,
  Output extends ModelType = ModelType
>(
  model: Model,
  transformer: (data: TypeOf<Model> | Infer<Model>) => Output,
  options?: {
    errorHandler?: (error: Error, context?: Partial<ScopeContext>) => void
  }
): ((data: ModelType) => Output) => {
  return (data: ModelType) => {
    const validation = model.safeParse(data, { path: ['builder'] })
    if (!validation.success) {
      const error = validation.error
      if (options?.errorHandler) {
        options.errorHandler(validation.error, { extra: { data } })
      } else {
        console.error(error, { extra: { data } })
      }
      return transformer(data)
    }
    return transformer(validation.data)
  }
}
