/**
 * This function is primarily intended to safeguard against conditions
 * which are not expected to occur under normal circumstances. It is not
 * recommended for use where data validation (e.g., validating user input
 * or unknown data) is required; a Zod schema would be more suitable
 * for those scenarios.
 *
 * @param condition The condition to check
 * @param message The message to display if the condition is false
 *
 * @example invariant(person.id, 'Expected value to be a person')
 * @example invariant(person.id, 'Unable to get id from person. This could happen if the API call failed at the network level and no data was returned.')
 */
export function invariant(
  condition: any,
  message?: string | (() => string)
): asserts condition {
  const prefix = 'Invariant failed'
  if (condition) {
    return
  }
  // if (isProduction) {
  //   throw new Error(prefix)
  // }
  const provided = typeof message === 'function' ? message() : message
  const value = provided ? prefix + ': ' + provided : prefix
  throw new Error(value)
}

export default invariant
