import * as z from 'zod'

const TraceContext = z.object({
  span_id: z.string(),
  trace_id: z.string(),
})

export const EventPayloadBase = z.object({
  id: z.string().uuid(),
  trigger: z.object({
    name: z.string(),
  }),
  table: z.object({
    name: z.string(),
    schema: z.string(),
  }),
  created_at: z.string(),
  delivery_info: z.object({
    max_retries: z.number(),
    current_retry: z.number(),
  }),
})

export const EventOperation = z.enum(['INSERT', 'UPDATE', 'DELETE', 'MANUAL'])

export function makeInsertPayload<
  TData extends z.ZodTypeAny,
  TSessionVariables extends z.ZodTypeAny
>(schema: TData, sessionVariables: TSessionVariables) {
  return EventPayloadBase.extend({
    event: createInsertEventSchema<TData, TSessionVariables>(
      schema,
      sessionVariables
    ),
  })
}

export function createInsertEventSchema<
  TData extends z.ZodTypeAny,
  TSessionVariables extends z.ZodTypeAny
>(schema: TData, sessionVariables: TSessionVariables) {
  return z.object({
    op: z.literal(EventOperation.enum.INSERT),
    data: z.object({
      new: schema,
      old: z.null(),
    }),
    trace_context: TraceContext.nullish(),
    session_variables: sessionVariables,
  })
}

export function makeUpdatePayload<
  TData extends z.ZodTypeAny,
  TSessionVariables extends z.ZodTypeAny
>(schema: TData, sessionVariables: TSessionVariables) {
  return EventPayloadBase.extend({
    event: createUpdateEventSchema<TData, TSessionVariables>(
      schema,
      sessionVariables
    ),
  })
}

export function createUpdateEventSchema<
  TData extends z.ZodTypeAny,
  TSessionVariables extends z.ZodTypeAny
>(schema: TData, sessionVariables: TSessionVariables) {
  return z.object({
    op: z.literal(EventOperation.enum.UPDATE),
    data: z.object({
      new: schema,
      old: schema,
    }),
    trace_context: TraceContext.nullish(),
    session_variables: sessionVariables,
  })
}

export function makeDeletePayload<
  TData extends z.ZodTypeAny,
  TSession extends z.ZodTypeAny
>(schema: TData, sessionVariables: TSession) {
  return EventPayloadBase.extend({
    event: createDeleteEventSchema<TData, TSession>(schema, sessionVariables),
  })
}

export function createDeleteEventSchema<
  TData extends z.ZodTypeAny,
  TSession extends z.ZodTypeAny
>(schema: TData, sessionVariables: TSession) {
  return z.object({
    op: z.literal(EventOperation.enum.DELETE),
    data: z.object({
      new: z.null(),
      old: schema,
    }),
    trace_context: TraceContext.nullish(),
    session_variables: sessionVariables,
  })
}

export function makeManualPayload<
  TData extends z.ZodTypeAny,
  TSession extends z.ZodTypeAny
>(schema: TData, sessionVariables: TSession) {
  return EventPayloadBase.extend({
    event: createManualEventSchema<TData, TSession>(schema, sessionVariables),
  })
}
export function createManualEventSchema<
  TData extends z.ZodTypeAny,
  TSession extends z.ZodTypeAny
>(schema: TData, sessionVariables: TSession) {
  return z.object({
    op: z.literal(EventOperation.enum.MANUAL),
    data: z.object({
      new: schema,
      old: z.null(),
    }),
    trace_context: TraceContext.nullish(),
    session_variables: sessionVariables,
  })
}

export function makeAllPayloads<
  TData extends z.ZodTypeAny,
  TSession extends z.ZodTypeAny
>(schema: TData, sessionVariables: TSession) {
  const InsertEvent = createInsertEventSchema<TData, TSession>(
    schema,
    sessionVariables
  )
  const UpdateEvent = createUpdateEventSchema<TData, TSession>(
    schema,
    sessionVariables
  )
  const DeleteEvent = createDeleteEventSchema<TData, TSession>(
    schema,
    sessionVariables
  )
  const ManualEvent = createManualEventSchema<TData, TSession>(
    schema,
    sessionVariables
  )
  return EventPayloadBase.extend({
    event: z.discriminatedUnion('op', [
      InsertEvent,
      UpdateEvent,
      DeleteEvent,
      ManualEvent,
    ]),
  })
}
